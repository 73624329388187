import React from "react"
import styled from "astroturf"

export const BlockOfOpportunity = () => {
  return (
    <Container className={"container"}>
      <Block>
        <Title>Нагрузочные испытания</Title>
        <Line />
        <Description>
          Напишем автоматизированные нагрузочные тесты, определим узкие места Вашей системы и составим детальный отчет с
          рекомендациями
        </Description>
      </Block>
      <Block>
        <Title>Ручное тестирование</Title>
        <Line />
        <Description>
          Наша команда QA-специалистов составит детальный тест-план и проведет ручное тестирование
        </Description>
      </Block>
      <Block>
        <Title>Автоматизированные тесты</Title>
        <Line />
        <Description>
          Заменим постоянные дорогие рутинные операции автоматизированными тестами, которые быстро и надежно проверяют
          основный функционал.
        </Description>
      </Block>
      <Block>
        <Title>Усилим Вашу команду</Title>
        <Line />
        <Description>
          У Вас дедлайн перед релизом и не хватает рук проверить все и вся, мы подключим наших специалистов в течение 1
          дня и поможем справиться с пожаром
        </Description>
      </Block>
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 150px;
  grid-row-gap: 80px;

  padding-top: 80px;

  @media (max-width: 1199px) {
    grid-column-gap: 50px;
  }

  @media (max-width: 991px) {
    grid-row-gap: 40px;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 420px) {
    padding-top: 40px;
  }
`

const Block = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.div`
  font-family: "Gilroy", sans-serif;
  font-weight: bold;
  font-size: 38px;
  line-height: 46px;
  color: #121e30;

  @media (max-width: 991px) {
    font-size: 24px;
  }
`

const Line = styled.div`
  width: 146px;
  height: 2px;
  background: #dfe5ec;

  margin: 20px 0;

  @media (max-width: 991px) {
    margin: 7px 0 14px 0;
  }
`

const Description = styled.div`
  font-family: "Gilroy", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #121e30;

  @media (max-width: 991px) {
    font-size: 16px;
    line-height: 22px;
  }
`
